import { Fragment, FunctionalComponent, h } from "preact";
import { Route, Router } from "preact-router";
import NotFoundPage from "../routes/notfound";
import Header from "./header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lookup from "../routes/lookup";
import Login from "../routes/login";
import NoPermissions from "../routes/nopermissions";
import { useState } from "preact/hooks";
import { clearTokens, ensureTokenRefreshCheckerStarted, getAuthorization, getTokenGrants } from "../services/token-service";
import { GrantsContext } from "../contexts/grants-context";

const App: FunctionalComponent = () => {
    ensureTokenRefreshCheckerStarted();
    const [grants, setGrants] = useState<string[] | undefined>(getTokenGrants());

    const logout = () => {
        clearTokens();
        setGrants(undefined);
    };

    const login = async (email?: string, password?: string) => {
        const emailRegex =
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if (!email) {
            toast("Email is required", { type: "error" });
            return;
        }
        if (!emailRegex.test(email)) {
            toast("Email is invalid", { type: "error" });
            return;
        }

        if (!password) {
            toast("Password is required", { type: "error" });
            return;
        }
        if (password.length < 6) {
            toast("Password must be at least 6 characters", { type: "error" });
            return;
        }

        if (!(await getAuthorization(email, password))) {
            toast("Login failed", { type: "error" });
            return;
        }

        setGrants(getTokenGrants());
    };

    const getInnerContent = () => {
        if (!grants) {
            return (
                <>
                    <Login login={login} />;
                    <ToastContainer position="top-right" autoClose={4000} pauseOnHover theme="dark" />
                </>
            );
        }

        return (
            <>
                <Header logout={logout} />
                <div class="mt-10 overflow-y-auto h-full pb-12">
                    {grants.length === 0 ? (
                        <NoPermissions />
                    ) : (
                        <GrantsContext.Provider value={grants}>
                            <Router>
                                <Route path="/" component={Lookup} />
                                <NotFoundPage default />
                            </Router>
                        </GrantsContext.Provider>
                    )}
                </div>
            </>
        );
    };

    return (
        <div id="preact_root" class="bg-gray-800 overflow-y-hidden">
            {getInnerContent()}
            <ToastContainer position="top-right" autoClose={4000} pauseOnHover theme="dark" />
        </div>
    );
};

export default App;
